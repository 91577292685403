import React from "react";

const LoadingScreen = ({ loading }) => {
  if (!loading) return null;

  return (
    <div className="loading-container">
      <div className="loader"></div>
    </div>
  );
};

export default LoadingScreen;
