import React from "react";
import { Routes, Route } from "react-router-dom";

import MainPage from "../pages/admin/MainPage";
import AddProduct from "../pages/admin/AddProduct";
import EditableContactUs from "../pages/admin/EditableContactUs";
import FileNotFound from "../pages/FileNotFound";
import AdminProducts from "../pages/admin/AdminProducts";
import ProductDetails from "../pages/admin/ProductDetails";
import UpdateProduct from "../pages/admin/UpdateProduct";
import ContactUsTable from "../pages/admin/ContactUsTable";

const AuthRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/contact-us" element={<EditableContactUs />} />
      <Route path="/requests" element={<ContactUsTable />} />
      <Route path="/products" element={<AdminProducts />} />
      <Route path="/products/detail" element={<ProductDetails />} />
      <Route path="/products/add-product" element={<AddProduct />} />
      <Route path="/products/update-product" element={<UpdateProduct />} />
      <Route path="*" element={<FileNotFound />} />
    </Routes>
  );
};

export default AuthRoutes;
