import React from "react";
import { NavLink } from "react-router-dom";

const NavItem = ({ to, label, onClick, ...rest }) => {
  return (
    <NavLink
      className={({ isActive }) =>
        isActive ? "nav-link selected" : "nav-link"
      }
      to={to}
      {...(onClick && {
        onClick: onClick,
      })}
      end
      {...rest}
    >
      {label}
    </NavLink>
  );
};

export default NavItem;
