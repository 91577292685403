import React from "react";

// columns []
// colums object = { path: '', label: '' };
// sortColumn: {path: , order: }

const TableHeader = ({ columns, sortColumn, onSort }) => {
  const raiseSort = (path) => {
    if (sortColumn && path) {
      const sort = sortColumn;
      if (sort.path === path) sort.order = sort.order === "asc" ? "desc" : "asc";
      else {
        sort.path = path;
        sort.order = "asc";
      }
      // console.log(sort);

      if (onSort) onSort(sort);
    }
  };

  const renderSortIcon = (column) => {
    if (!sortColumn || column.key) return null;

    if (column.path !== sortColumn.path) return null;
    if (sortColumn.order === "asc") return <i className="icon-sortup"></i>;

    return <i className="icon-sortdown"></i>;
  };

  const setPointerEvent = (column) =>
    sortColumn && column.sortable !== false ? "cursor-pointer" : "";

  const handleSorting = (column) => {
    if (column.sortable === false) return;
    raiseSort(column.path);
  };

  return (
    <thead className="table-light">
      <tr>
        {columns &&
          columns.map((column, index) => (
            <th
              className={setPointerEvent(column)}
              key={column.path || column.key || index}
              onClick={() => handleSorting(column)}
            >
              {column.label} {renderSortIcon(column)}
            </th>
          ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
