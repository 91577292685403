import React from "react";
import { Routes, Route } from "react-router-dom";

import Home from "../pages/Home";
import Login from "../pages/admin/Login";
import Products from "../pages/Products";
import ContactUsPage from "../pages/ContactUsPage";
import ProductDetails from "../pages/ProductDetails";
import FileNotFound from "../pages/FileNotFound";

const AppRoutes = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/products" element={<Products />} />
      <Route path="/contact-us" element={<ContactUsPage />} />
      <Route path="/products/detail" element={<ProductDetails />} />
      <Route path="*" element={<FileNotFound />} />
    </Routes>
  );
};

export default AppRoutes;
