import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getContents,
  isLoading,
  updateContent,
  loadContents,
} from "../../store/content";

import Button from "../../components/common/Button";

import LoadingScreen from "../../pages/LoadingScreen";

import withAdminNavigation from "../../hoc/withAdminNavigation";

const EditableContactUs = () => {
  const dispatch = useDispatch();
  const content = useSelector(getContents);

  const contentLoading = useSelector(isLoading);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(loadContents());
  }, []);

  const editable = (name) => ({
    spellCheck: false,
    contentEditable: true,
    suppressContentEditableWarning: true,
    "data-name": name,
  });

  const save = () => {
    const fields = document.querySelectorAll("[data-name]");
    const content = {};
    fields.forEach((field) => {
      if (
        content[field.dataset.name] &&
        content[field.dataset.name] === field.innerText.trim()
      ) {
      } else {
        content[field.dataset.name] = field.innerText.trim();
      }
    });

    dispatch(updateContent(content));
  };

  return (
    <>
      <LoadingScreen loading={contentLoading} />

      <section className="contact-us bottom-slider">
        {/* <div className="big-top-circle5"></div> */}
        <div className="container">
          {/* <div className="icon-close" onClick={closeSlider}></div> */}

          <div className="row justify-content-md-center align-items-md-center">
            <div className="col-md-6 col-lg-6">
              <p
                className="section-heading-top text-start"
                {...editable("contact_label")}
              >
                {content?.contact_label}
              </p>
              <h1
                className="section-heading text-start"
                {...editable("contact_title")}
              >
                {content?.contact_title}
              </h1>
              <p className="color-grey" {...editable("contact_content")}>
                {content?.contact_content}
              </p>

              <h4 className="mt-4">Address</h4>
              <p className="color-grey" {...editable("footer_address")}>
                {content?.footer_address}
              </p>

              <h4 className="mt-4">Phone Number</h4>
              <p className="color-grey" {...editable("footer_contact")}>
                {content?.footer_contact}
              </p>

              <h4 className="mt-4">Email </h4>
              <p className="color-grey" {...editable("footer_email")}>
                {content?.footer_email}
              </p>
            </div>
            <div className="col-md-6 col-lg-6 pt-md-0 pt-lg-0 pt-5">
              {/* <div className="form-content">
                <div className="flipped-backgroud"></div>
                <div className="form-container"></div>
              </div> */}
            </div>
          </div>
        </div>
      </section>

      <div className="floating-btn">
        <Button onClick={save}>Save</Button>
      </div>
    </>
  );
};

export default withAdminNavigation(EditableContactUs);
