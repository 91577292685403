import AuthProvider from "./context/AuthProvider";
import MainNavigation from "./navigation/MainNavigation";

function App() {
  return (
    <AuthProvider>
      <MainNavigation />
    </AuthProvider>
  );
}

export default App;
