import TableBody from "./TableBody";
import TableHeader from "./TableHeader";

const Table = ({
  columns,
  data,
  sortColumn,
  loading,
  LoadingComponent,
  onSort,
}) => {
  return (
    <div className="table-responsive  bg-white">
      <div className="table-container">
        <table className="table">
          <TableHeader
            columns={columns}
            onSort={onSort}
            sortColumn={sortColumn}
          />
          <TableBody
            data={data}
            columns={columns}
            loading={loading}
            LoadingComponent={LoadingComponent}
          />
        </table>
      </div>
    </div>
  );
};

export default Table;
