import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { loadProducts, getProducts, isLoading } from "../store/products";
import {
  loadContents,
  getContents,
  isLoading as contentLoading,
} from "../store/content";

import withNavigation from "../hoc/withNavigation";
import LoadingScreen from "./LoadingScreen";
import ProductCard from "../components/ProductCard";

const Products = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const content = useSelector(getContents);
  const products = useSelector(getProducts);
  const isContentLoading = useSelector(contentLoading);
  const loading = useSelector(isLoading);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(loadContents());
    dispatch(loadProducts());
  }, []);

  return (
    <>
      {/* OUR PRODUCTS SECTION */}
      <LoadingScreen loading={loading || isContentLoading} />

      <section className="our-products product-page">
        <div className="container">
          <p className="section-heading-top">{content?.our_products_label}</p>
          <h1 className="section-heading">{content?.our_products_title}</h1>
          <p className="section-description">{content?.our_products_content}</p>

          <div className="row mt-5">
            {products?.map((product) => (
              <div className="col-md-4" key={product.id}>
                <ProductCard
                  name={product.name}
                  image={product.image}
                  description={product.description}
                  onClick={() =>
                    navigate("/products/detail", { state: product })
                  }
                />
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  );
};

export default withNavigation(Products);
