import React from "react";

import AdminNavbar from "../components/AdminNavbar";
import EditableFooter from "../components/EditableFooter";

const withAdminNavigation = (Component) => {
  return (props) => {
    return (
      <>
        <AdminNavbar />
        <Component {...props} />
        <EditableFooter />
      </>
    );
  };
};

export default withAdminNavigation;
