import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import withNavigation from "../hoc/withNavigation";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { state: product } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <section className="product-details">
        <div className="big-top-circle6"></div>
        <div className="container">
          <div
            className="d-flex align-items-center back-button "
            onClick={() => navigate(-1)}
          >
            <i className="icon-back"></i>
            <p>Back</p>
          </div>

          <div className="row mt-4">
            <div className="col-md-6 col-lg-6 order-2 order-md-1 pt-5 pt-md-0">
              <div className="content">
                <h1>{product.name}</h1>
                <p className="desc">{product.description}</p>

                <div className="ps-md-0 pe-md-0 ps-lg-0 pe-lg-0 ps-3 pe-3">
                  <div className="row details">
                    {Object.entries(product?.properties).map((value, index) => {
                      const item = value[1].split("$");
                      const k = item[0];
                      const v = item[1];

                      return (
                        <div
                          key={value}
                          className={`col-md-${
                            (index + 1) % 3 === 0 ||
                            (index % 3 == 0 &&
                              index + 1 ===
                                Object.entries(product?.properties).length)
                              ? "12"
                              : "6"
                          } mb-4 pt-3 text-md-start text-center text-lg-start`}
                        >
                          <h6>{k}</h6>
                          <p>⦁ {v}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 order-1 order-md-2">
              <div className="image-container">
                <div className="flipped-box"></div>
                <img src={product?.image} alt="" loading="lazy" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withNavigation(ProductDetails);
