import React from "react";

import InputText from "./InputText";

const KeyValueInput = ({ keyField, valueField, register, error, onClose }) => {
  return (
    <div className="row justify-content-end">
      <div className="col-5">
        <InputText label={"Title"} name={keyField} register={register} />
      </div>
      <div className="col-5">
        <InputText label={"Info."} name={valueField} register={register} />
      </div>
      <div className="col-2">
        <div className="close" onClick={onClose}>
          <i className="icon-close"></i>
        </div>
      </div>

      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default KeyValueInput;
