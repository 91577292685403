import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import OTPInput from "otp-input-react";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

import InputText from "../../components/common/InputText";
import Button from "../../components/common/Button";

import { navLogo } from "../../assets";

import "../../firebase/firebase";
import AuthContext from "../../context/AuthContext";

const OTP_LENGTH = 6;

const schema = Yup.object({
  phone: Yup.string()
    .matches(/^[6-9]{1}[0-9]{9}$/, { message: "Invalid phone number" })
    .oneOf(
      ["9726503452", "9081888829", "9662290907", "6354416334"],
      "Unauthorized accessed"
    )
    .label("Contact Number")
    .required("Enter contact number"),
});

const Login = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });
  const { setIsLoggedIn } = useContext(AuthContext);
  const [OTP, setOTP] = useState("");
  const [data, setData] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const auth = getAuth();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    setData(data);
    createRecaptchVerifier();
    startSignIn("+91" + data.phone);
  };

  const createRecaptchVerifier = () => {
    setLoading(true);

    window.recaptchaVerifier = new RecaptchaVerifier(
      "sign-in-button",
      {
        size: "invisible",
        callback: (response) => {},
      },
      auth
    );
  };

  const startSignIn = (phoneNumber) => {
    signInWithPhoneNumber(auth, phoneNumber, window.recaptchaVerifier)
      .then((confirmationResult) => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        setError("Unable to perform authentication. Please try again");
      })
      .finally(() => {
        window.recaptchaVerifier = null;
        setLoading(false);
      });
  };

  const verifyOtp = () => {
    if (OTP.length !== OTP_LENGTH) {
      return setError("Enter Otp");
    }

    setLoading(true);

    window.confirmationResult
      .confirm(OTP)
      .then((result) => {
        const user = result.user;
        // After the user verifed
        // decode the token and login
        navigate("/");
        localStorage.setItem("user", JSON.stringify(user));
        setIsLoggedIn(true);
      })
      .catch((error) => {
        setError(error.message);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="login-container">
      <div id="sign-in-button"></div>
      <div className="row h-100 w-100 m-0">
        <div className="col-md-4 left">
          <img src={navLogo} alt="" />

          <h4 className="mt-5">
            Login in to continue with your account here !
          </h4>
        </div>
        <div className="col-md-8  right row align-items-center m-0 p-0">
          <div className="container login-content">
            {data ? (
              <>
                <h1>Enter Otp !</h1>
                <p className="color-grey">
                  Enter your phone number to login to the system. We will send
                  you 6 digit OTP for verification.
                </p>
                <p className="color-grey mb-4">+91 {data.phone}</p>

                <OTPInput
                  value={OTP}
                  onChange={setOTP}
                  autoFocus
                  OTPLength={OTP_LENGTH}
                  otpType="number"
                  inputClassName="form-control otp-box me-2"
                  className="mb-3"
                />
                {error && <p className="error">{error}</p>}

                <Button
                  className="w-50 mt-3"
                  onClick={verifyOtp}
                  loading={loading}
                >
                  Verify Otp
                </Button>
              </>
            ) : (
              <>
                <h1>Login Here !</h1>
                <p className="color-grey mb-4">
                  Enter your phone number to login to the system. We will send
                  you 6 digit OTP for verification.
                </p>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <InputText
                    type="text"
                    label="Phone Number"
                    name="phone"
                    register={register}
                    error={errors?.phone?.message}
                    maxLength={10}
                  />

                  <Button className="w-50 mt-3">Login</Button>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
