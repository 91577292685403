import { combineReducers } from "redux";

import contentReducer from "./content";
import productReducer from "./products";
import contactReducer from "./contacts";

export default combineReducers({
  contents: contentReducer,
  products: productReducer,
  contacts: contactReducer,
});
