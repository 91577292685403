import React, { useEffect, useContext } from "react";
import { getAuth } from "firebase/auth";

import AuthContext from "../context/AuthContext";

import AuthRoutes from "./AuthRoutes";
import AppRoutes from "./AppRoutes";

const MainNavigation = () => {
  const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      if (user) setIsLoggedIn(true);
      else setIsLoggedIn(false);
    });
  }, []);

  return isLoggedIn ? <AuthRoutes /> : <AppRoutes />;
};

export default MainNavigation;
