import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

import {
  loadContents,
  getContents,
  isLoading as contentLoading,
  updateContent,
} from "../../store/content";
import {
  loadProducts,
  getProducts,
  isLoading,
  removeProduct,
  updateProducts,
} from "../../store/products";

import Button from "../../components/common/Button";
import LoadingScreen from "../LoadingScreen";
import withAdminNavigation from "../../hoc/withAdminNavigation";
import AdminProductCard from "../../components/AdminProductCard";

const FORMATE_TABLE = "FORMATE_TABLE";
const FORMATE_LIST = "FORMATE_LIST";

const AdminProducts = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const content = useSelector(getContents);
  const products = useSelector(getProducts);
  const isContentLoading = useSelector(contentLoading);
  const loading = useSelector(isLoading);
  const [formate, setFormate] = useState(FORMATE_LIST);
  const [items, setItems] = useState(products);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(loadContents());
    dispatch(loadProducts());
  }, []);

  const handleAdd = () => navigate("/products/add-product");

  const handleDelete = (id) => {
    if (window.confirm("Do you want to remove this product ?")) {
      dispatch(removeProduct(id));
    }
  };

  const editable = (name) => ({
    spellCheck: false,
    contentEditable: true,
    suppressContentEditableWarning: true,
    "data-name": name,
  });

  const save = () => {
    const fields = document.querySelectorAll("[data-name]");
    const content = {};
    fields.forEach((field) => {
      if (content[field.dataset.name]) {
        const temp = content[field.dataset.name];

        if (Array.isArray(temp)) {
          content[field.dataset.name] = [...temp, field.innerText.trim()];
        } else {
          content[field.dataset.name] = [temp, field.innerText.trim()];
        }
      } else {
        content[field.dataset.name] = field.innerText.trim();
      }
    });

    // console.log(content);
    dispatch(updateContent(content));
  };

  const toggleFormate = () => {
    setFormate(
      (prev) => (prev = prev === FORMATE_LIST ? FORMATE_TABLE : FORMATE_LIST)
    );
  };

  const handleDragEnd = (e) => {
    if (!e.destination) return;

    let tempData = Array.from(products);

    let [source_data] = tempData.splice(e.source.index, 1);
    tempData.splice(e.destination.index, 0, source_data);

    dispatch(updateProducts(tempData));
  };

  // const columns = [];
  return (
    <>
      {/* OUR PRODUCTS SECTION */}
      <LoadingScreen loading={loading || isContentLoading} />

      <section className="our-products product-page">
        <div className="container">
          <p
            className="section-heading-top"
            {...editable("our_products_label")}
          >
            {content?.our_products_label}
          </p>
          <h1 className="section-heading" {...editable("our_products_title")}>
            {content?.our_products_title}
          </h1>
          <p
            className="section-description"
            {...editable("our_products_content")}
          >
            {content?.our_products_content}
          </p>

          <div className="d-flex justify-content-center align-items-center">
            <button className="btn btn-primary text-center" onClick={handleAdd}>
              Add Product
            </button>
          </div>

          <div className="row mt-5 ">
            <div className="col-12 d-flex justify-content-end align-items-center sort">
              <i
                className={`fa-solid ${
                  formate === FORMATE_LIST ? "fa-sharp fa-table" : "fa-list"
                }`}
                onClick={toggleFormate}
              ></i>
            </div>

            {formate === FORMATE_LIST ? (
              products?.map((product) => {
                return (
                  <div className="col-md-4" key={product.id}>
                    <AdminProductCard
                      name={product.name}
                      image={product.image}
                      description={product.description}
                      onDelete={() => handleDelete(product.id)}
                      onUpdate={() =>
                        navigate("/products/update-product", { state: product })
                      }
                    />
                  </div>
                );
              })
            ) : (
              <DragDropContext onDragEnd={handleDragEnd}>
                <div className="table-container product-table">
                  <table className="table borderd">
                    <thead className="table-light">
                      <tr>
                        <th />
                        <th className="col">Product</th>

                        <th className="col">Action</th>
                      </tr>
                    </thead>

                    <Droppable droppableId="droppable-1">
                      {(provider) => (
                        <tbody
                          className="text-capitalize"
                          ref={provider.innerRef}
                          {...provider.droppableProps}
                        >
                          {products?.map((item, index) => (
                            <Draggable
                              key={item.id}
                              draggableId={item.id}
                              index={index}
                            >
                              {(provider) => (
                                <tr
                                  {...provider.draggableProps}
                                  ref={provider.innerRef}
                                >
                                  <td {...provider.dragHandleProps}>
                                    <div className="drag">=</div>
                                  </td>
                                  <td>
                                    <div className="d-flex align-items-center">
                                      <img src={item.image} alt="" />
                                      <div>
                                        <p className="ms-1 mb-0">{item.name}</p>
                                      </div>
                                    </div>
                                  </td>

                                  <td>
                                    <div className="d-flex align-items-center actions">
                                      <button
                                        className="btn btn-danger"
                                        onClick={() => handleDelete(item.id)}
                                      >
                                        Delete
                                      </button>
                                      <button
                                        className="btn btn-secondary ms-2"
                                        onClick={() =>
                                          navigate("/products/update-product", {
                                            state: item,
                                          })
                                        }
                                      >
                                        Update
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </Draggable>
                          ))}
                          {provider.placeholder}
                        </tbody>
                      )}
                    </Droppable>
                  </table>
                </div>
              </DragDropContext>
            )}
          </div>
        </div>
      </section>

      <div className="floating-btn">
        <Button onClick={save}>Save</Button>
      </div>
    </>
  );
};

export default withAdminNavigation(AdminProducts);
