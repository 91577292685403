import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap/dist/js/bootstrap.bundle'
import "bootstrap/dist/js/bootstrap";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faTrash, faEdit } from "@fortawesome/free-solid-svg-icons";

import "./fonts/style.css";
import "./index.css";
import "./styles.scss";
import App from "./App";

import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
const store = configureStore();

library.add(faTrash, faEdit);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>
);
