import * as actions from "../api";

const api =
  ({ dispatch }) =>
  (next) =>
  async (action) => {
    if (action.type !== actions.apiCallBegan.type) return next(action);

    var {
      url,
      method,
      headers,
      firebase,
      data,
      body,
      onStart,
      onSuccess,
      onError,
    } = action.payload;

    if (onStart) dispatch({ type: onStart });
    next(action);

    try {
      if (firebase) {
        const response = await firebase(data);
        dispatch({ type: onSuccess, payload: response });
      }
    } catch (error) {
      // Generic Error
      console.log(error);
      dispatch(
        actions.apiCallFailed(
          error.response?.data?.message || error.response?.data?.sqlMessage
        )
      );

      // Specific Error
      if (onError) {
        if (Array.isArray(onError)) {
          onError.forEach((err) =>
            dispatch({
              type: err,
              payload:
                error.response?.data?.message ||
                error.response?.data?.sqlMessage ||
                error.response?.data ||
                "Something wrong !",
            })
          );
        } else {
          dispatch({
            type: onError,
            payload:
              error.response?.data?.message ||
              error.response?.data?.sqlMessage ||
              error.response?.data ||
              "Something wrong !",
          });
        }
      }
    }
  };

export default api;
