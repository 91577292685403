import React from "react";

const FileNotFound = () => {
  return (
    <div>
      <h4 className="text-center">File Not Found</h4>
    </div>
  );
};

export default FileNotFound;
