import React, { useLayoutEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { NavHashLink } from "react-router-hash-link";

import { navLogo } from "../assets";
import logo from "../assets/Park-Logo.webp";

import NavItem from "./common/NavItem";

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const openSlider = (e) => {
    navigate("/contact-us");

    e.preventDefault();

    const slider = document.querySelector(".contact-us");
    slider.classList.add("active");
  };

  const navItems = [
    {
      id: 1,
      label: "Home",
      href: "/#",
    },
    {
      id: 2,
      label: "About",
      href: "/#about-us",
    },
    {
      id: 3,
      label: "Products",
      to: "/products",
    },
    {
      id: 4,
      label: "Why Us",
      href: "/#why-us",
    },
    {
      id: 5,
      label: "Contact Us",
      to: "/contact-us",
      // onClick: openSlider,
    },
  ];

  useLayoutEffect(() => {
    const navbar = document.querySelector(".navbar");
    const navButton = document.querySelector(".navbar-toggler");
    const navLinks = document.querySelectorAll(".nav-link");
    const navBarCollaps = document.querySelector(".navbar-collapse");

    window.addEventListener("scroll", (e) => {
      if (window.scrollY > 40) {
        navbar.classList.add("active");
      } else {
        navbar.classList.remove("active");
      }
    });

    navButton.addEventListener("click", (e) => {
      if (!navbar.classList.contains("active")) {
        navbar.classList.toggle("active");
      }
    });

    navLinks.forEach((link) => {
      link.addEventListener("click", () => {
        if (window.innerWidth <= 984) navButton.click();
      });
    });

    document
      .querySelector(".navbar-toggler")
      .addEventListener("click", function () {
        const iconEl = document.getElementsByTagName("span")[0];

        if (this.getAttribute("aria-expanded") === "true") {
          iconEl.classList.add("icon-close");
          iconEl.classList.remove("navbar-toggler-icon");
        } else {
          iconEl.classList.remove("icon-close");
          iconEl.classList.add("navbar-toggler-icon");
        }
      });
  });

  const getNavClassLink = (href) => {
    if (`${location.pathname}${location.hash}` === "/" && href === "/#")
      return "nav-link selected";
    else if (`${location.pathname}${location.hash}` === href)
      return "nav-link selected";
    else return "nav-link";
  };

  return (
    <>
      <div className="big-top-circle1"></div>
      <nav className="navbar fixed-top navbar-expand-lg navbar-light">
        <div className="container">
          <Link className="navbar-brand" to={"/"}>
            <img src={logo} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav m-auto mb-lg-0">
              {navItems.map((nav) => (
                <li key={nav.id} className="nav-item">
                  {nav.href ? (
                    <NavHashLink
                      to={nav.href}
                      activeClassName=""
                      className={getNavClassLink(nav.href)}
                      smooth
                    >
                      {nav.label}
                    </NavHashLink>
                  ) : (
                    <NavItem
                      to={nav.to}
                      onClick={nav.onClick}
                      label={nav.label}
                      end
                    />
                  )}
                </li>
              ))}
            </ul>

            <div
              className="btn btn-primary d-none d-md-block d-lg-block"
              onClick={openSlider}
            >
              Get In Touch
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Navbar;
