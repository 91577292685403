import React from "react";
import { useNavigate } from "react-router-dom";
import { setContent } from "../../../firebase/firebase";

const AboutUs = ({ content }) => {
  const navigate = useNavigate();

  return (
    <section className="about-us" id="about-us">
      <div className="big-top-circle6"></div>
      <div className="container">
        <div className="box1">
          <div className="box2"></div>
          <div className="content">
            <p
              className="section-heading-top"
              spellCheck="false"
              onInput={(e) => console.log("e: ", e.currentTarget.textContent)}
              contentEditable={true}
              suppressContentEditableWarning={true}
              data-name="about_us_label"
            >
              {content?.about_us_label}
            </p>
            <h1
              className="section-heading mt-2"
              spellCheck="false"
              onInput={(e) => console.log("e: ", e.currentTarget.textContent)}
              contentEditable={true}
              suppressContentEditableWarning={true}
              data-name="about_us_title"
            >
              {content?.about_us_title}
            </h1>
            <p
              className="section-description mt-3"
              spellCheck="false"
              onInput={(e) => console.log("e: ", e.currentTarget.textContent)}
              contentEditable={true}
              suppressContentEditableWarning={true}
              data-name="about_us_content"
            >
              {content?.about_us_content}
            </p>

            <button
              className="btn btn-primary text-center mt-3"
              //   onClick={() => navigate("/contact-us")}
            >
              Get In Touch
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
