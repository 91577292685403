import React, { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";

import {
  getContents,
  isLoading,
  updateContent,
  loadContents,
} from "../../store/content";
import {
  getContacts,
  loadContacts,
  removeContact,
  isLoading as contactLoading,
} from "../../store/contacts";

import Table from "../../components/Table";
import Button from "../../components/common/Button";

import LoadingScreen from "../../pages/LoadingScreen";

import withAdminNavigation from "../../hoc/withAdminNavigation";

const ContactUsTable = () => {
  const dispatch = useDispatch();
  const content = useSelector(getContents);
  const contacts = useSelector(getContacts);
  const loading1 = useSelector(isLoading);
  const loading2 = useSelector(contactLoading);

  const handleDelete = (id) => {
    if (window.confirm("Do you want to remove this record ?"))
      dispatch(removeContact(id));
  };

  const columns = [
    {
      path: "name",
      label: "Name",
    },
    {
      path: "phone",
      label: "Phone Number",
      content: (item) => (
        <div>
          <h5 className="mb-0">{item.phone}</h5>
          <p className="m-0">{item.email}</p>
        </div>
      ),
    },
    {
      path: "subject",
      label: "Subject",
    },
    {
      path: "comment",
      label: "Comment",
    },
    {
      key: "",
      content: (item) => {
        return (
          <div className="d-flex justify-content-between align-items-center">
            <button
              className="btn btn-danger"
              onClick={() => handleDelete(item.id)}
            >
              Delete
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(loadContents());
    dispatch(loadContacts());
  }, []);

  const save = () => {
    const fields = document.querySelectorAll("[data-name]");
    const content = {};
    fields.forEach((field) => {
      if (
        content[field.dataset.name] &&
        content[field.dataset.name] === field.innerText.trim()
      ) {
      } else {
        content[field.dataset.name] = field.innerText.trim();
      }
    });

    dispatch(updateContent(content));
  };

  return (
    <>
      <LoadingScreen loading={loading1 || loading2} />

      <section className="contact-us">
        <div className="container">
          <h2>All Requests</h2>
          <Table columns={columns} data={contacts} />
        </div>
      </section>

      <div className="floating-btn">
        <Button onClick={save}>Save</Button>
      </div>
    </>
  );
};

export default withAdminNavigation(ContactUsTable);
