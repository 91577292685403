import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";

const withNavigation = (Component) => {
  const handleCall = () => {
    window.open(`tel:+919726503452`);
  };

  const handleWhatsapp = () => {
    window.open(`https://wa.me/916354416334`);
  };

  const handleMail = () => {
    window.open(`mailto:export@parkoverseas.in`);
  }

  return (props) => {
    return (
      <>
        <Navbar />
        <Component {...props} />
        <Footer />

        <div className="social-media-buttons">
          <div>
            <i className="fa-solid fa-envelope d-block mb-2" onClick={handleMail}></i>
            <i className="fa-solid fa-phone" onClick={handleCall}></i>
          </div>
          <i className="icon-whatsup-bold" onClick={handleWhatsapp}></i>
        </div>
      </>
    );
  };
};

export default withNavigation;
