import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useDispatch, useSelector } from "react-redux";

import {
  loadContents,
  getContents,
  isLoading as contentLoading,
} from "../store/content";

import { navLogo } from "../assets";
import logo from "../assets/Park-Logo.webp";

const EditableFooter = () => {
  const dispatch = useDispatch();
  const content = useSelector(getContents);

  useEffect(() => {
    dispatch(loadContents());
  }, []);

  const editable = (name) => ({
    spellCheck: false,
    contentEditable: true,
    suppressContentEditableWarning: true,
    "data-name": name,
  });

  return (
    <section className="footer pb-4">
      <div className="big-top-circle4"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-lg-5">
            <img src={logo} />
            <p className="mt-3" {...editable("footer_content")}>
              {content?.footer_content}
            </p>

            <ul className="d-flex gap-3">
              <li>
                <i className="icon-facebook"></i>
              </li>
              <li>
                <i className="icon-whatsup"></i>
              </li>

              <li>
                <i className="icon-linckedin"></i>
              </li>
              <li>
                <i className="icon-instagram"></i>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-lg-3 pt-3">
            <h5>Links</h5>
            <ul>
              <li>
                <Link to={"/#"}>Home</Link>
              </li>
              <li>
                <HashLink to={"/#about-us"}>About</HashLink>
              </li>
              <li>
                <Link to={"/products"}>Products</Link>
              </li>
              <li>
                <HashLink to={"/#why-us"}>Why Us</HashLink>
              </li>
              <li>
                <Link to={"/contact-us"}>Contact Us</Link>
              </li>
            </ul>
          </div>
          <div className="col-md-3 col-lg-3 pt-3 contacts">
            <h5>Contacts</h5>
            <p className="address" {...editable("footer_address")}>
              {content?.footer_address}
            </p>
            <p className="email" {...editable("footer_email")}>
              <p></p> {content?.footer_email}
            </p>
            <p className="email" {...editable("footer_email1")}>
              {content?.footer_email1}
            </p>
            <p className="phone" {...editable("footer_contact")}>
              {content?.footer_contact}
            </p>
          </div>
        </div>
        <div className="divider"></div>
        <p className="text-md-center text-lg-center m-0 mt-2 align-items-center d-flex justify-content-md-center">
          <i className="icon-copyright "></i>
          <span> 2023 All Right Reserved</span>
        </p>
      </div>
    </section>
  );
};

export default EditableFooter;
