import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  loadContents,
  getContents,
  isLoading as contentLoading,
} from "../store/content";
import {
  loadProducts,
  getProducts,
  isLoading as productLoading,
} from "../store/products";

import ProductCard from "../components/ProductCard";

import {
  makeInIndia,
  goGreen,
  recycle,
  ecoFriendly,
  swachBharat,
} from "../assets";

import withNavigation from "../hoc/withNavigation";
import LoadingScreen from "./LoadingScreen";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const content = useSelector(getContents);
  const products = useSelector(getProducts);
  const loading1 = useSelector(contentLoading);
  const loading2 = useSelector(productLoading);

  useEffect(() => {
    dispatch(loadContents());
    dispatch(loadProducts());
  }, []);

  return (
    <>
      <LoadingScreen loading={loading1 || loading2} />
      <section className="hero-section">
        <div className="container">
          <div className="row align-items-md-center">
            <div className="col-md-6 col-xl-6 pe-md-0 pe-lg-0 order-2 order-md-1">
              <div className="half-border">
                <p className="hint">{content?.home_label}</p>
                <h1>{content?.home_title}</h1>
                <p className="description">{content?.home_content}</p>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/products")}
                >
                  View All Products
                </button>
              </div>
            </div>

            <div className="col-md-6 col-xl-6 ps-md-0 ps-lg-0 order-1 order-md-2">
              <div className="image-container">
                <div className="flipped-box"></div>
                <div
                  id="carouselExampleInterval"
                  className="carousel slide pointer-event"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    {content?.carousel?.map((image, index) => (
                      <div
                        className={`carousel-item ${index === 0 && "active"}`}
                        data-bs-interval="3500"
                        key={image}
                      >
                        <img
                          src={image}
                          className="d-block w-100"
                          // alt={"File not found"}
                        />
                      </div>
                    ))}
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleInterval"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* HIGHLIGHTS ITEMS  */}
      {/* <img src={bigCircle2} className="backdrop2" alt="" /> */}
      <section className="highlights">
        <div className="container">
          <h2 className="text-bold mb-4 section-heading">
            We Make Eco Friendly Products
          </h2>
          <div className="row align-items-center">
            <div className="col-6 col-md-2 col-lg-2 text-center pb-4 pt-4">
              <img src={makeInIndia} alt="" />
            </div>
            <div className="col-6 col-md-3 col-lg-3 text-center pb-4 pt-4">
              <img src={goGreen} alt="" />
            </div>
            <div className="col-6 col-md-2 col-lg-2 text-center pb-4 pt-4">
              <img src={ecoFriendly} alt="" />
            </div>
            <div className="col-6 col-md-3 col-lg-3 text-center pb-4 pt-4">
              <img src={recycle} alt="" />
            </div>
            <div className="col-6 col-md-2 col-lg-2 text-center pb-4 pt-4">
              <img src={swachBharat} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* OUR PRODUCTS SECTION */}
      <section className="our-products">
        <div className="big-top-circle2"></div>

        <div className="container">
          <p className="section-heading-top">{content?.our_products_label}</p>
          <h1 className="section-heading">{content?.our_products_title}</h1>
          <p className="section-description">{content?.our_products_content}</p>

          <div className="row mt-5">
            {_.take(products, 3).map((product) => (
              <div className="col-md-4" key={product.id}>
                <ProductCard
                  image={product.image}
                  name={product.name}
                  description={product.description}
                  onClick={() =>
                    navigate("/products/detail", { state: product })
                  }
                />
              </div>
            ))}
          </div>

          <div className="d-flex justify-content-center align-items-center">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/products")}
            >
              View All Products
            </button>
          </div>
        </div>
      </section>

      {/* WHY US SECTION */}
      <section className="why-us" id="why-us">
        <div className="big-top-circle3"></div>

        <div className="container">
          <div className="row ">
            <div className="col-md-6 col-lg-6 pe-md-0 pe-lg-0">
              <div className="image-container">
                <div className="flipped-box"></div>
                <img src={content?.why_us_image} loading="lazy" />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 ps-md-0 ps-lg-0 mt-md-5">
              <div className="half-border">
                <p className="section-heading-top text-start">
                  {content?.why_us_label}
                </p>
                <h1 className="section-heading text-start">
                  {content?.why_us_title}
                </h1>
                <p className="mt-4 color-grey">{content?.why_us_content}</p>

                <ul className="why-us-list">
                  {content?.why_us_list?.map((item) => (
                    <li className="color-grey" key={item}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ABOUT US */}
      <section className="about-us" id="about-us">
        <div className="big-top-circle6"></div>
        <div className="container">
          <div className="box1">
            <div className="box2"></div>
            <div className="content">
              <p className="section-heading-top">{content?.about_us_label}</p>
              <h1 className="section-heading mt-2">
                {content?.about_us_title}
              </h1>
              <p className="section-description mt-3">
                {content?.about_us_content}
              </p>

              <button
                className="btn btn-primary text-center mt-3"
                onClick={() => navigate("/contact-us")}
              >
                Get In Touch
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withNavigation(Home);
