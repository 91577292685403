import React from "react";

const AdminProductCard = ({
  image,
  name,
  description,
  onClick,
  onDelete,
  onUpdate,
}) => {
  return (
    <div className="product-card " onClick={onClick}>
      <img src={image} alt="" loading="lazy" />
      <h4>{name}</h4>
      <p>{description}</p>
      {/* <i className="icon-forword"></i> */}
      <div className="d-flex justify-content-center align-items-center">
        <button className="btn btn-danger" onClick={onDelete}>
          Delete
        </button>
        <button className="btn btn-secondary ms-2" onClick={onUpdate}>
          Update
        </button>
      </div>
    </div>
  );
};

export default AdminProductCard;
