import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";

import { getPathStorageFromUrl, updateProduct } from "../../firebase/firebase";
import { validURL } from "../../utils/url";

import InputText from "../../components/common/InputText";
import InputTextArea from "../../components/common/InputTextArea";
import KeyValueInput from "../../components/common/KeyValueInput";
import Button from "../../components/common/Button";
import withAdminNavigation from "../../hoc/withAdminNavigation";

const schema = Yup.object({
  name: Yup.string()
    .label("Product Name")
    .trim()
    .required("Enter product name"),
  description: Yup.string()
    .label("Product Description")
    .trim()
    .required("Enter product description"),
  file: Yup.mixed(),
  properties: Yup.array().of(
    Yup.object().shape({
      key: Yup.string()
        .label("Key")
        .trim()
        .typeError("Invalid key")
        .required("Enter value"),
      value: Yup.string()
        .label("Value")
        .trim()
        .typeError("Invalid value")
        .required("Enter value"),
    })
  ),
});

const UpdateProduct = () => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
    setValue,
  } = useForm({ resolver: yupResolver(schema) });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  });

  const { state: product } = useLocation();

  const [file, setFile] = useState(product.image);
  const [loading, setLoading] = useState(false);

  const loadFile = async () => {
    const file = await createFile(product.image);
    setFile(file);
  };

  useEffect(() => {
    window.scrollTo(0, 0);

    Object.keys(product).map((key) => {
      setValue(key, product[key], { shouldDirty: true, shouldValidate: true });

      if (key === "properties") {
        Object.keys(product[key]).map((k) => {
          const value = product[key][k].split("$");
          append({
            id: k,
            key: value[0],
            value: value[1],
          });
        });
      }
    });

    loadFile();
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    const properties = {};
    data.properties.map((item, index) => {
      properties[index + 1] = `${item.key}$${item.value}`;
    });

    data.properties = properties;

    if (!validURL(file)) data.file = file;

    try {
      const id = data.id;
      delete data.id;
      const result = await updateProduct(id, data);
      alert("Your product has been updated");
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="product-details">
      <div className="big-top-circle6"></div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-6 col-lg-6 order-2 order-md-1 pt-5 pt-md-0">
              <div className="content">
                <InputText
                  label={"Product Name"}
                  name="name"
                  register={register}
                  error={errors?.name?.message}
                />

                <InputTextArea
                  label={"Description"}
                  height={120}
                  name="description"
                  register={register}
                  error={errors?.description?.message}
                />

                <div className="bar"></div>

                {fields.map(({ id }, index) => (
                  <KeyValueInput
                    key={id}
                    keyField={`properties[${index}].key`}
                    valueField={`properties[${index}].value`}
                    register={register}
                    onClose={() => remove(index)}
                  />
                ))}

                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => append({ key: null, value: null })}
                >
                  Add
                </button>

                <Button loading={loading} className="mt-4 w-50">
                  Submit
                </Button>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 order-1 order-md-2">
              <div className="image-container">
                <div className="flipped-box"></div>

                <div className="upload-container">
                  {file ? (
                    <img
                      src={validURL(file) ? file : URL.createObjectURL(file)}
                      alt=""
                      loading="lazy"
                    />
                  ) : (
                    <div className="file-input-container text-center">
                      <h5>Upload Your File Here</h5>
                      <p className="m-0">Click or drag your file here. </p>
                      {errors?.file?.message && (
                        <p className="error">{errors?.file?.message}</p>
                      )}
                    </div>
                  )}

                  <Controller
                    name="file"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <input
                        type="file"
                        className="product-image-picker"
                        onChange={(e) => {
                          onChange(e);
                          setFile(e.target.files[0]);
                        }}
                        accept="image/jpg, image/jpeg, image/png, image/webp"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

async function createFile(url) {
  let response = await fetch(url);
  let data = await response.blob();
  let metadata = {
    type: "image/jpeg",
  };

  const path = getPathStorageFromUrl(url);

  let file = new File([data], path.split("/")[1], metadata);
  return file;
}

export default withAdminNavigation(UpdateProduct);
