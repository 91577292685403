import React from "react";

const InputTextArea = ({
  name,
  register,
  label,
  height = 100,
  error,
  ...rest
}) => {
  return (
    <div className="form-floating mb-3">
      <textarea
        className="form-control"
        id="floatingTextarea2"
        style={{ height: `${height}px` }}
        placeholder={label}
        {...(register && register(name))}
        {...rest}
      ></textarea>
      <label htmlFor="floatingTextarea2">{label}</label>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default InputTextArea;
