import React from "react";

const InputText = ({ type, name, register, id, error, label, ...rest }) => {
  return (
    <div className="mb-3">
      <div className="form-floating ">
        <input
          type={type}
          className="form-control"
          id={id}
          placeholder={label}
          {...(register && register(name))}
          {...rest}
        />
        <label htmlFor={id}>{label}</label>
      </div>
      {error && <p className="error">{error}</p>}
    </div>
  );
};

export default InputText;
