import React from "react";

const ProductCard = ({ image, name, description, onClick }) => {
  return (
    <div className="product-card" onClick={onClick}>
      <img src={image} alt="" loading="lazy" />
      <h4>{name}</h4>
      <p>{description}</p>
      <i className="icon-forword"></i>
    </div>
  );
};

export default ProductCard;
