import React from "react";
import _ from "lodash";

const TableBody = ({ data, columns, loading, LoadingComponent }) => {
  const renderCell = (item, column) => {
    if (column.content) return column.content(item);

    return _.get(item, column.path);
  };

  const renderRow = (data) => {
    if (data.length > 0) {
      return data.map((item, index1) => (
        <tr key={index1}>
          {columns &&
            columns.map((column, index2) => (
              <td key={index2}>{renderCell(item, column)}</td>
            ))}
        </tr>
      ));
    }

    if (!loading && data.length === 0)
      return (
        <tr>
          <td colSpan={10}>
            <h3 className="m-0 text-center text-bold">Nothing Found</h3>
          </td>
        </tr>
      );
  };

  return (
    <tbody>
      {loading && _.range(3).map((i) => <LoadingComponent key={i} />)}

      {data && renderRow(data)}
    </tbody>
  );
};

export default TableBody;
