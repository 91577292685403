import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import _ from "lodash";

import { getContact, deleteContact } from "../firebase/firebase";

import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "contact",
  initialState: {
    contact: null,
    loading: false,
    lastFetch: null,
  },
  reducers: {
    contactRequested: (contacts, action) => {
      contacts.loading = true;
    },

    contactRequestFailed: (contacts, action) => {
      contacts.loading = false;
    },

    contactRequestCompleted: (contacts, action) => {
      contacts.loading = false;
    },

    contactsReceived: (contacts, action) => {
      contacts.contact = action.payload;
      contacts.loading = false;
      contacts.lastFetch = Date.now();
    },

    contactDeleted: (contacts, action) => {
      const temp = contacts.contact;
      contacts.contact = temp.filter((t) => t.id !== action.payload);
    },
  },
});

const {
  contactRequestFailed,
  contactRequested,
  contactsReceived,
  contactRequestCompleted,
  contactDeleted,
} = slice.actions;
export default slice.reducer;

// Action Creator
const url = "/contacts";

export const loadContacts = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.contacts;

  const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      firebase: getContact,
      onStart: contactRequested.type,
      onSuccess: contactsReceived.type,
      onError: contactRequestFailed.type,
    })
  );
};

export const removeContact = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      firebase: deleteContact,
      onSuccess: contactRequestCompleted.type,
      onError: contactRequestFailed.type,
      data: id,
    })
  );

  dispatch(contactDeleted(id));
};
// Selector
export const getContacts = (states) => states.entities.contacts.contact;

export const isLoading = (states) => states.entities.contacts.loading;
