import React from "react";

const Button = ({
  children,
  loading = false,
  className,
  type = "submit",
  ...rest
}) => {
  return (
    <button
      type={type}
      disabled={loading}
      className={`btn btn-primary my-btn ${className}`}
      {...rest}
    >
      {loading === true ? <div className="loading"></div> : children}
    </button>
  );
};

export default Button;
