import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import _ from "lodash";

import { getContent, setContent } from "../firebase/firebase";

import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "content",
  initialState: {
    content: null,
    loading: false,
    lastFetch: null,
  },
  reducers: {
    contentRequested: (contents, action) => {
      contents.loading = true;
    },

    contentRequestFailed: (contents, action) => {
      contents.loading = false;
    },

    contentsReceived: (contents, action) => {
      contents.content = action.payload;
      contents.loading = false;
      contents.lastFetch = Date.now();
    },

    contentsUpdated: (contents, action) => {
      contents.content = action.payload;
      contents.loading = false;
      contents.lastFetch = Date.now();
    },
  },
});

const { contentRequestFailed, contentRequested, contentsReceived } =
  slice.actions;
export default slice.reducer;

// Action Creator
const url = "/contents";

export const loadContents = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.contents;

  const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      firebase: getContent,
      onStart: contentRequested.type,
      onSuccess: contentsReceived.type,
      onError: contentRequestFailed.type,
    })
  );
};

// Selector
export const getContents = (states) => states.entities.contents.content;

export const updateContent = (content) => (dispatch, getState) => {
  const { content: data } = getState().entities.contents;

  const payload = { ...data, ...content };

  dispatch(
    apiCallBegan({
      firebase: setContent,
      onError: contentRequestFailed.type,
      onSuccess: contentsReceived.type,
      data: payload,
    })
  );

  dispatch(contentsReceived(payload));
};

export const isContentLoadingFirstTime = (states) =>
  states.entities.contents.loading &&
  states.entities.contents.lastFetch == null;

export const isLoading = (states) => states.entities.contents.loading;
