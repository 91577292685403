import React, { useEffect, useState } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { addProduct } from "../../firebase/firebase";
import { getProducts, loadProducts } from "../../store/products";

import InputText from "../../components/common/InputText";
import InputTextArea from "../../components/common/InputTextArea";
import KeyValueInput from "../../components/common/KeyValueInput";
import Button from "../../components/common/Button";
import withAdminNavigation from "../../hoc/withAdminNavigation";

const schema = Yup.object({
  name: Yup.string()
    .label("Product Name")
    .trim()
    .required("Enter product name"),
  description: Yup.string()
    .label("Product Description")
    .trim()
    .required("Enter product description"),
  file: Yup.mixed()
    .required("A file is required")
    .test("fileFormat", "Pick a image", (value) => value !== null),
  properties: Yup.array()
    .of(
      Yup.object().shape({
        key: Yup.string()
          .label("Key")
          .typeError("Invalid key")
          .trim()
          .required("Enter value"),

        value: Yup.string()
          .label("Value")
          .trim()
          .typeError("Invalid value")
          .required("Enter value"),
      })
    )
    .test("unique", "Only unique values allowed.", (value) => {
      const set = new Set();
      for (var i of value) {
        set.add(i.key);
      }
      return value.length === set.size;
    }),
});

const AddProduct = () => {
  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm({ resolver: yupResolver(schema) });
  const { fields, append, remove } = useFieldArray({
    control,
    name: "properties",
  });
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const products = useSelector(getProducts);

  useEffect(() => {
    window.scrollTo(0, 0);

    dispatch(loadProducts());
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    const properties = {};

    data.properties.map((item, index) => {
      properties[index + 1] = `${item.key}$${item.value}`;
    });

    data.properties = properties;
    data.file = file;
    data.index = products.length;

    try {
      await addProduct(data);
      alert("Your product is added successfully");
      reset();
      setFile(null);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <section className="product-details">
      <div className="big-top-circle6"></div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-6 col-lg-6 order-2 order-md-1 pt-5 pt-md-0">
              <div className="content">
                <InputText
                  label={"Product Name"}
                  name="name"
                  register={register}
                  error={errors?.name?.message}
                />

                <InputTextArea
                  label={"Description"}
                  height={120}
                  name="description"
                  register={register}
                  error={errors?.description?.message}
                />

                <div className="bar"></div>

                {fields.map(({ id }, index) => (
                  <KeyValueInput
                    key={id}
                    keyField={`properties[${index}].key`}
                    valueField={`properties[${index}].value`}
                    error={
                      Array.isArray(errors?.properties) &&
                      errors?.properties[index]?.key?.message
                    }
                    register={register}
                    onClose={() => remove(index)}
                  />
                ))}

                {errors?.properties?.message && (
                  <p className="error">{errors?.properties?.message}</p>
                )}
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>
                    append({ id: Date.now(), key: null, value: null })
                  }
                >
                  Add
                </button>

                <Button loading={loading} className="mt-4 w-50">
                  Submit
                </Button>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 order-1 order-md-2">
              <div className="image-container">
                <div className="flipped-box"></div>

                <div
                  className="upload-container"
                  style={{ padding: file ? "0" : "5em" }}
                >
                  {file ? (
                    <img src={URL.createObjectURL(file)} alt="" />
                  ) : (
                    <div className="file-input-container text-center">
                      <h5>Upload Your File Here</h5>
                      <p className="m-0">Click or drag your file here. </p>
                      {errors?.file?.message && (
                        <p className="error">{errors?.file?.message}</p>
                      )}
                    </div>
                  )}

                  <Controller
                    name="file"
                    control={control}
                    render={({ field: { onChange } }) => (
                      <input
                        type="file"
                        className="product-image-picker"
                        onChange={(e) => {
                          onChange(e);
                          setFile(e.target.files[0]);
                        }}
                        accept="image/jpg, image/jpeg, image/png, image/webp"
                      />
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </section>
  );
};

export default withAdminNavigation(AddProduct);
