import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import withAdminNavigation from "../../hoc/withAdminNavigation";

import { removeFile } from "../../firebase/firebase";

const ProductDetails = () => {
  const navigate = useNavigate();
  const { state: product } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const deleteFile = (file) => {
    removeFile(file);
  };

  return (
    <>
      <section className="product-details">
        {/* <div className="big-top-circle6"></div> */}
        <div className="container">
          <div
            className="d-flex align-items-center back-button "
            onClick={() => navigate(-1)}
          >
            <i className="icon-back"></i>
            <p>Back</p>
          </div>

          <div className="row mt-4">
            <div className="col-md-6 col-lg-6 order-2 order-md-1 pt-5 pt-md-0">
              <div className="content">
                <h1>{product.name}</h1>
                <p className="desc">{product.description}</p>

                <div className="ps-md-0 pe-md-0 ps-lg-0 pe-lg-0 ps-3 pe-3">
                  <div className="row details">
                    {Object.entries(product?.properties).map((value, index) => (
                      <div
                        key={value[0]}
                        className={`col-md-${
                          (index + 1) % 3 === 0 ? "12" : "6"
                        } mb-4 pt-3 text-md-start text-center text-lg-start`}
                      >
                        <h6>{value[1]}</h6>
                        <p>⦁ {value[0].replace("_", " ").toUpperCase()}</p>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-6 col-lg-6 order-1 order-md-2">
              <button
                className="btn btn-primary"
                onClick={() => deleteFile(product?.image)}
              >
                Delete
              </button>
              <div className="image-container">
                <div className="flipped-box"></div>
                <img src={product?.image} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withAdminNavigation(ProductDetails);
