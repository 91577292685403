import { initializeApp } from "firebase/app";
import {
  doc,
  addDoc,
  getFirestore,
  collection,
  getDoc,
  getDocs,
  setDoc,
  deleteDoc,
  query,
  orderBy,
  where,
} from "firebase/firestore";
import {
  getDownloadURL,
  getStorage,
  ref,
  uploadBytes,
  deleteObject,
} from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

const CONTACT = "contact";
const CONTENTS = "contents";
const PRODUCTS = "products";

const PRODUCT_STORAGE = "product_images";
const IMAGES_STORAGE = "images";

const contactRef = collection(db, CONTACT);
const contentRef = collection(db, CONTENTS);
const productRef = collection(db, PRODUCTS);

const storageRef = ref(storage, PRODUCT_STORAGE);
const imageStorageRef = ref(storage, IMAGES_STORAGE);

export const addContact = (contact) => addDoc(contactRef, contact);

export const addProduct = async (product) => {
  const file = product.file;
  const location = ref(storage, `product_images/${file.name}`);

  try {
    const { ref: fileRef } = await uploadBytes(location, file);
    const url = await getDownloadURL(fileRef);
    delete product.file;
    product.image = url;
    return addDoc(productRef, product);
  } catch (err) {
    throw err;
  }
};

export const updateProduct = async (id, product) => {
  const file = product.file;
  try {
    if (file) {
      const location = ref(storage, `product_images/${file.name}`);
      const { ref: fileRef } = await uploadBytes(location, file);
      const url = await getDownloadURL(fileRef);
      product.image = url;
    }

    delete product.file;
    return setDoc(doc(db, PRODUCTS, id), product);
  } catch (err) {
    throw err;
  }
};

export const updateProductIndex = async (id, product) =>
  setDoc(doc(db, PRODUCTS, id), product);

export const uploadFile = (file) => {
  const location = ref(storage, `product_images/${file.name}`);

  getDownloadURL(ref(storage, `product_images/${file.name}`));

  return uploadBytes(location, file);
};

export const uploadWhyUsImage = async (file) => {
  const location = ref(storage, `${IMAGES_STORAGE}/${file.name}`);
  const { ref: fileRef } = await uploadBytes(location, file);
  return getDownloadURL(fileRef);
};

export const getContent = async () => {
  const docSnap = await getDoc(doc(db, "contents", "V4qmn9C1xHsjvrYe0FU2"));

  if (docSnap.exists()) {
    return docSnap.data();
  } else {
    return null;
  }
};

export const setContent = async (content) => {
  try {
    await setDoc(doc(db, "contents", "V4qmn9C1xHsjvrYe0FU2"), content);
  } catch (e) {
    throw e;
  }

  return content;
};

export const getProduct = async () => {
  const q = query(productRef, orderBy("index"));
  const result = await getDocs(q);

  const data = [];
  result.docs.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });

  return data;
};

export const getContact = async () => {
  const result = await getDocs(contactRef);

  const data = [];
  result.docs.forEach((doc) => {
    data.push({ id: doc.id, ...doc.data() });
  });

  return data;
};

export const deleteContact = (id) => deleteDoc(doc(db, CONTACT, id));

export const deleteProduct = (id) => deleteDoc(doc(db, PRODUCTS, id));

export const removeFile = (url) => {
  const fileRef = ref(storage, getPathStorageFromUrl(url));
  if (fileRef != null) return deleteObject(fileRef);
  return null;
};

export const signOut = () => getAuth().signOut();

export function getPathStorageFromUrl(url) {
  const baseUrl =
    "https://firebasestorage.googleapis.com/v0/b/park-overseas.appspot.com/o/";

  let imagePath = url.replace(baseUrl, "");

  const indexOfEndPath = imagePath.indexOf("?");

  imagePath = imagePath.substring(0, indexOfEndPath);

  imagePath = imagePath.replace("%2F", "/");

  return imagePath;
}
