import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";
import _ from "lodash";

import {
  getProduct,
  deleteProduct,
  updateProductIndex,
} from "../firebase/firebase";

import { apiCallBegan } from "./api";

const slice = createSlice({
  name: "product",
  initialState: {
    product: null,
    loading: false,
    lastFetch: null,
  },
  reducers: {
    productRequested: (products, action) => {
      products.loading = true;
    },

    productRequestFailed: (products, action) => {
      products.loading = false;
    },

    productRequestCompleted: (products, action) => {
      products.loading = false;
    },

    productsReceived: (products, action) => {
      products.product = action.payload;
      products.loading = false;
      products.lastFetch = Date.now();
    },

    productsIndexChanged: (products, action) => {
      products.product = action.payload;
    },

    productRemoved: (products, action) => {
      const temp = products.product;
      products.product = temp.filter((t) => t.id !== action.payload);
    },
  },
});

const {
  productRequestFailed,
  productRequested,
  productsReceived,
  productRequestCompleted,
  productRemoved,
  productsIndexChanged,
} = slice.actions;
export default slice.reducer;

// Action Creator

export const loadProducts = () => (dispatch, getState) => {
  const { lastFetch } = getState().entities.products;

  const diffInMinutes = moment().diff(moment(lastFetch), "minutes");
  if (diffInMinutes < 10) return;

  dispatch(
    apiCallBegan({
      firebase: getProduct,
      onStart: productRequested.type,
      onSuccess: productsReceived.type,
      onError: productRequestFailed.type,
    })
  );
};

export const removeProduct = (id) => (dispatch, getState) => {
  dispatch(
    apiCallBegan({
      firebase: deleteProduct,
      onSuccess: productRequestCompleted.type,
      onError: productRequestFailed.type,
      data: id,
    })
  );

  dispatch(productRemoved(id));
};

export const updateProducts = (list) => (dispatch, getState) => {
  // update the database here

  dispatch(productsIndexChanged(list));

  try {
    for (var i = 0; i < list.length; i++) {
      updateProductIndex(list[i].id, { ...list[i], index: i });
    }
  } catch (error) {
    console.log("unable to update the index of the product");
  }
};

// Selector
export const getProducts = (states) => states.entities.products.product;

export const isLoading = (states) => states.entities.products.loading;
