import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import {
  loadContents,
  getContents,
  isLoading as contentLoading,
} from "../../store/content";
import {
  loadProducts,
  getProducts,
  isLoading as productLoading,
  removeProduct,
} from "../../store/products";
import { updateContent } from "../../store/content";

import { removeFile, uploadWhyUsImage } from "../../firebase/firebase";

import {
  makeInIndia,
  goGreen,
  recycle,
  ecoFriendly,
  swachBharat,
} from "../../assets";
import { validURL } from "../../utils/url";

import LoadingScreen from "../LoadingScreen";
import AboutUs from "./sections/AboutUs";
import Button from "../../components/common/Button";
import withAdminNavigation from "../../hoc/withAdminNavigation";
import AdminProductCard from "../../components/AdminProductCard";

const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const content = useSelector(getContents);
  const products = useSelector(getProducts);
  const loading1 = useSelector(contentLoading);
  const loading2 = useSelector(productLoading);

  const [isFileUploading, setIsFileUploading] = useState(false);
  const [isFileDeleting, setIsFileDeleting] = useState(false);

  const [file, setFile] = useState();

  const [carousel, setCarousel] = useState([]);
  const [deleteIds, setDeleteIds] = useState([]);
  const [uploadIds, setUploadIds] = useState([]);
  const [filesNotFoundIndex, setFilesNotFoundIndex] = useState([]);

  useEffect(() => {
    dispatch(loadContents());
    dispatch(loadProducts());
  }, []);

  useEffect(() => {
    if (content) {
      setFilesNotFoundIndex([]);
      content.carousel?.map((img, index) => {
        checkImage(img, (status) => {
          if (status === 404) {
            const temp = [...filesNotFoundIndex];
            temp.push(index);
            setFilesNotFoundIndex(temp);
          }
        });
      });
      setCarousel(content.carousel);
    }
  }, [content]);

  const save = () => {
    const fields = document.querySelectorAll("[data-name]");
    const content = {};
    fields.forEach((field) => {
      if (field.dataset.name === "why_us_content") {
        console.log(field.innerHTML);
      }

      if (content[field.dataset.name]) {
        const temp = content[field.dataset.name];

        if (Array.isArray(temp)) {
          content[field.dataset.name] = [...temp, field.innerText];
        } else {
          content[field.dataset.name] = [temp, field.innerText];
        }
      } else {
        content[field.dataset.name] = field.innerText;
      }
    });

    // console.log(content);
    dispatch(updateContent(content));
  };

  const handleDelete = (id) => {
    if (window.confirm("Do you want to delete this product ?")) {
      dispatch(removeProduct(id));
    }
  };

  const onFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUploadWhyUsImage = async () => {
    setIsFileUploading(true);
    try {
      const url = await uploadWhyUsImage(file);
      dispatch(updateContent({ why_us_image: url }));
      setFile(null);
    } catch (e) {
      console.log(e);
    } finally {
      setIsFileUploading(false);
    }
  };

  const deleteImage = async (url) => {
    setIsFileDeleting(true);

    try {
      await removeFile(url);
      window.location.reload();
    } catch (e) {
      console.log(e);
    } finally {
      setIsFileDeleting(false);
    }
  };

  const deleteCarouselImage = async (index, image) => {
    try {
      const ids = [...deleteIds];
      ids.push(index);
      setDeleteIds(ids);

      await removeFile(image);

      const images = [...content.carousel];

      dispatch(updateContent({ carousel: images }));
      window.location.reload();
    } catch (e) {
      console.log(e);
    } finally {
      var ids = [...deleteIds];
      ids = ids.filter((i) => i !== index);
      setDeleteIds(ids);
    }
  };

  const editable = (name) => ({
    spellCheck: false,
    contentEditable: true,
    suppressContentEditableWarning: true,
    "data-name": name,
    style: { whiteSpace: "pre-line" },
  });

  const onCarouselFilePick = (image, index, file) => {
    const item = [...carousel];
    item[index] = file;
    setCarousel(item);
  };

  const carouselFileUpload = async (index) => {
    try {
      const ids = [...uploadIds];
      ids.push(index);
      setUploadIds(ids);

      const url = await uploadWhyUsImage(carousel[index]);

      const images = [...content.carousel];
      images[index] = url;

      dispatch(updateContent({ carousel: images }));
    } catch (e) {
      console.log(e);
    } finally {
      var ids = [...deleteIds];
      ids = ids.filter((i) => i !== index);
      setUploadIds(ids);
    }
  };

  return (
    <>
      <LoadingScreen loading={loading1 || loading2} />
      <section className="hero-section">
        <div className="container">
          <div className="row align-items-md-center">
            <div className="col-md-6 col-xl-6 pe-md-0 pe-lg-0 order-2 order-md-1">
              <div className="half-border">
                <p className="hint" {...editable("home_label")}>
                  {content?.home_label}
                </p>
                <h1 spellCheck="false" {...editable("home_title")}>
                  {content?.home_title}
                </h1>
                <p className="description" {...editable("home_content")}>
                  {content?.home_content}
                </p>
                <button
                  className="btn btn-primary"
                  onClick={() => navigate("/products")}
                >
                  View All Products
                </button>
              </div>
            </div>

            <div className="col-md-6 col-xl-6 ps-md-0 ps-lg-0 order-1 order-md-2">
              <div className="image-container">
                <p className="note">
                  * Please upload image with landscape size like (400 x 350)
                </p>
                <div className="flipped-box"></div>

                <div
                  id="carouselExampleIndicators"
                  className="carousel slide"
                  data-interval="false"
                >
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="2"
                      aria-label="Slide 3"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="3"
                      aria-label="Slide 4"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#carouselExampleIndicators"
                      data-bs-slide-to="4"
                      aria-label="Slide 5"
                    ></button>
                  </div>

                  <div className="carousel-inner">
                    <div className="carousel-inner">
                      {carousel?.map((image, index) => (
                        <div
                          className={`carousel-item ${
                            index == 0 ? "active" : ""
                          }`}
                        >
                          <div className="icons carousel-items">
                            <i
                              className={`fa-solid ${
                                deleteIds.includes(index)
                                  ? "fa-spinner rotate"
                                  : "fa-trash"
                              }`}
                              onClick={() => {
                                if (!deleteIds.includes(index))
                                  deleteCarouselImage(index, image);
                              }}
                            ></i>

                            <i
                              className="fa-solid fa-upload"
                              onClick={() => {
                                document.getElementById(image).click();
                              }}
                            ></i>
                            <input
                              type="file"
                              name=""
                              accept="image/png, image/jpg, image/jpeg, image/webp"
                              id={image}
                              onChange={(e) =>
                                onCarouselFilePick(
                                  image,
                                  index,
                                  e.target.files[0]
                                )
                              }
                              hidden={true}
                            />
                            {!validURL(image) && (
                              <i
                                className={`fa-solid ${
                                  uploadIds.includes(index)
                                    ? "fa-spinner rotate"
                                    : "fa-check"
                                }`}
                                onClick={() => {
                                  if (!deleteIds.includes(index))
                                    carouselFileUpload(index);
                                }}
                              ></i>
                            )}
                          </div>
                          <img
                            src={
                              image && validURL(image)
                                ? image
                                : URL.createObjectURL(image)
                            }
                            className="d-block w-100"
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleIndicators"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* HIGHLIGHTS ITEMS  */}
      {/* <img src={bigCircle2} className="backdrop2" alt="" /> */}
      <section className="highlights">
        <div className="container">
          <h2 className="text-bold mb-4 section-heading">
            We Make Eco Friendly Products
          </h2>
          <div className="row align-items-center">
            <div className="col-6 col-md-2 col-lg-2 text-center pb-4 pt-4">
              <img src={makeInIndia} alt="" />
            </div>
            <div className="col-6 col-md-3 col-lg-3 text-center pb-4 pt-4">
              <img src={goGreen} alt="" />
            </div>
            <div className="col-6 col-md-2 col-lg-2 text-center pb-4 pt-4">
              <img src={ecoFriendly} alt="" />
            </div>
            <div className="col-6 col-md-3 col-lg-3 text-center pb-4 pt-4">
              <img src={recycle} alt="" />
            </div>
            <div className="col-6 col-md-2 col-lg-2 text-center pb-4 pt-4">
              <img src={swachBharat} alt="" />
            </div>
          </div>
        </div>
      </section>

      {/* OUR PRODUCTS SECTION */}
      <section className="our-products">
        <div className="big-top-circle2"></div>

        <div className="container">
          <p
            className="section-heading-top"
            {...editable("our_products_label")}
          >
            {content?.our_products_label}
          </p>
          <h1 className="section-heading" {...editable("our_products_title")}>
            {content?.our_products_title}
          </h1>
          <p
            className="section-description"
            {...editable("our_products_content")}
          >
            {content?.our_products_content}
          </p>

          <div className="row mt-5">
            {_.take(products, 3).map((product) => (
              <div className="col-md-4" key={product.id}>
                <AdminProductCard
                  image={product.image}
                  name={product.name}
                  description={product.description}
                  onDelete={() => handleDelete(product.id)}
                  onUpdate={() =>
                    navigate("/products/update-product", { state: product })
                  }
                />
              </div>
            ))}
          </div>

          <div className="text-center d-flex justify-content-center align-items-center">
            <button
              className="btn btn-primary"
              onClick={() => navigate("/products")}
            >
              View All Products
            </button>
          </div>
        </div>
      </section>

      {/* WHY US SECTION */}
      <section className="why-us" id="why-us">
        <div className="big-top-circle3"></div>

        <div className="container">
          <p className="note">
            * Please upload image with landscape size like (800 x 600)
          </p>
          <div className="icons">
            <i
              className={`fa-solid ${
                isFileDeleting ? "fa-spinner rotate" : "fa-trash"
              }`}
              onClick={() => {
                if (!isFileDeleting) deleteImage(content?.why_us_image);
              }}
            ></i>
            <i
              className="fa-solid fa-upload"
              onClick={() => {
                document.getElementById("why-us-image").click();
              }}
            ></i>
            <input
              type="file"
              name=""
              id="why-us-image"
              accept="image/png, image/jpg, image/jpeg, image/webp"
              onChange={onFileChange}
              hidden={true}
            />
            {/* <i className="fa-solid fa-edit"></i> */}

            {file && (
              <i
                className={`fa-solid ${
                  isFileUploading ? "fa-spinner rotate" : "fa-check"
                }`}
                onClick={handleUploadWhyUsImage}
              ></i>
            )}
          </div>
          <div className="row ">
            <div className="col-md-6 col-lg-6 pe-md-0 pe-lg-0">
              {/* <div className="d-flex align-items-center">
                <button className="btn btn-primary">Upload</button>
                <button className="btn btn-danger">Delete</button>
              </div> */}

              <div className="image-container">
                <div className="flipped-box"></div>
                <img
                  src={file ? URL.createObjectURL(file) : content?.why_us_image}
                />
              </div>
            </div>
            <div className="col-md-6 col-lg-6 ps-md-0 ps-lg-0 mt-md-5">
              <div className="half-border">
                <p
                  className="section-heading-top text-start"
                  {...editable("why_us_label")}
                >
                  {content?.why_us_label}
                </p>
                <h1
                  className="section-heading text-start"
                  {...editable("why_us_title")}
                >
                  {content?.why_us_title}
                </h1>

                <p className="mt-4 color-grey" {...editable("why_us_content")}>
                  {content?.why_us_content}
                </p>

                <ul className="why-us-list">
                  {content?.why_us_list?.map((item) => (
                    <li
                      className="color-grey"
                      key={item}
                      {...editable("why_us_list")}
                    >
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* ABOUT US */}
      <AboutUs content={content} />

      <div className="floating-btn">
        <Button onClick={save}>Save</Button>
      </div>
    </>
  );
};

function checkImage(url, callback) {
  var request = new XMLHttpRequest();
  request.open("GET", url, true);
  request.send();
  request.onload = function () {
    if (callback) return callback(this.status);
  };
}

export default withAdminNavigation(Home);
