import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";

import { getContents, isLoading } from "../store/content";

import InputText from "../components/common/InputText";
import LoadingScreen from "../pages/LoadingScreen";

import { addContact } from "../firebase/firebase";
import withNavigation from "../hoc/withNavigation";
import InputTextArea from "../components/common/InputTextArea";
import Button from "../components/common/Button";

const schema = Yup.object({
  name: Yup.string().label("Name").required("Enter name"),
  phone: Yup.string()
    .matches(/^[6-9]{1}[0-9]{9}$/, { message: "Invalid phone number" })
    .label("Contact Number")
    .required("Enter contact number"),
  email: Yup.string().label("Email").required("Enter email"),
  subject: Yup.string().label("Subject").required("Enter subject"),
  comment: Yup.string().label("Comment").required("Enter comment"),
});

const ContactUsPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    reset,
    formState: { errors },
    handleSubmit,
  } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const content = useSelector(getContents);
  const contentLoading = useSelector(isLoading);
  const [loading, setLoading] = useState(false);

  const closeSlider = () => {
    const slider = document.querySelector(".contact-us");
    slider.classList.remove("active");
  };

  const onSubmit = async (data) => {
    setLoading(true);

    try {
      await addContact(data);
      reset();
      alert("Your request have been submitted");
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <LoadingScreen loading={contentLoading} />

      <section className="contact-us bottom-slider">
        {/* <div className="big-top-circle5"></div> */}
        <div className="container">
          {/* <div className="icon-close" onClick={closeSlider}></div> */}

          <div className="row justify-content-md-center align-items-md-center">
            <div className="col-md-6 col-lg-6">
              <p className="section-heading-top text-start">
                {content?.contact_label}
              </p>
              <h1 className="section-heading text-start">
                {content?.contact_title}
              </h1>
              <p className="color-grey">{content?.contact_content}</p>

              <h4 className="mt-4">Address</h4>
              <p className="color-grey">{content?.footer_address}</p>

              <h4 className="mt-4">Phone Number</h4>
              <p className="color-grey">{content?.footer_contact}</p>

              <h4 className="mt-4">Email </h4>
              <p className="color-grey"> {content?.footer_email}</p>
            </div>
            <div className="col-md-6 col-lg-6 pt-md-0 pt-lg-0 pt-5">
              <div className="form-content">
                <div className="flipped-backgroud"></div>
                <div className="form-container">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <InputText
                      type="text"
                      label="Name"
                      id="floatingName"
                      placeholder="Name"
                      name="name"
                      register={register}
                      error={errors?.name?.message}
                    />

                    <InputText
                      type="text"
                      label="Contact Number"
                      id="floatingContactNumber"
                      placeholder="Contact Number"
                      name="phone"
                      register={register}
                      error={errors?.phone?.message}
                      maxLength={15}
                    />

                    <InputText
                      type="email"
                      label="Email"
                      id="floatingEmail"
                      placeholder="Email"
                      name="email"
                      register={register}
                      error={errors?.email?.message}
                    />

                    <InputText
                      type="text"
                      label="Subject"
                      id="floatingSubject"
                      placeholder="Subject"
                      name="subject"
                      register={register}
                      error={errors?.subject?.message}
                    />

                    <InputTextArea
                      label="How we can help you ?"
                      name="comment"
                      register={register}
                      error={errors?.comment?.message}
                    />

                    <Button loading={loading} className="w-50" type="submit">
                      Submit
                    </Button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default withNavigation(ContactUsPage);
